<template>
  <div style="background-color: #fff">
    <div class="recharge-step van-hairline--bottom">
      <span style="font-size: 1.5rem">Step 1： {{ $t("请转账至") }}</span>
      <div style="padding: 10px">
        {{ $t("银行") }}： {{ bankInfo.bankname }}
        <div @click="copyBankAccount()">
          {{ $t("账号") }}： {{ bankInfo.account }}
          <span>{{ $t("[复制]") }}</span>
        </div>
        {{ $t("户名") }}： {{ bankInfo.username }}
      </div>
    </div>
    <div class="recharge-step van-hairline--bottom">
      <span style="font-size: 1.5rem">Step 2： {{ $t("上传网银截图") }}</span>

      <div style="text-align: center; padding: 20px 0px">
        <van-uploader
          v-model="fileList"
          :after-read="afterRead"
          multiple
          :max-count="1"
        />
      </div>
      <van-field
        v-model="amount"
        :label="$t('金额')"
        type="number"
        maxlength="10"
        :placeholder="$t('请输入充值金额')"
      />

      <div class="saveButton">
        <van-button
          @click="doSubmit"
          type="primary"
          :loading="submitLoading"
          :disabled="submitLoading"
        >
          {{ $t("确定") }}
        </van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { Uploader, Field, Button, Toast } from "vant";
import { getBankInfo, uploadScreenshot } from "@/service/api";

export default {
  components: {
    [Uploader.name]: Uploader,
    [Field.name]: Field,
    [Button.name]: Button,
  },
  data() {
    return {
      submitLoading: false,
      bankInfo: {},
      fileList: [],
      amount: "",
      file: null,
    };
  },
  mounted() {
    this.getBankInfo();
  },
  methods: {
    afterRead(file) {
      this.file = file;
    },
    async uploadScreenshot() {
      let formData = new FormData();
      formData.append("amount", this.amount);
      formData.append("file", this.file.file);

      this.submitLoading = true;

      const res = await uploadScreenshot(formData);
      if (res.success) {
        Toast.success("提交成功，请等待审核");
        this.$router.go(-1);
      } else Toast.fail(res.message);
      this.submitLoading = false;
    },
    copyBankAccount() {
      let oInput = document.createElement("input");
      oInput.value = this.bankInfo.account;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      document.execCommand("Copy"); // 执行浏览器复制命令
      Toast.success(this.$t("复制成功"));
      oInput.remove();
    },
    async getBankInfo() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const res = await getBankInfo();

      if (res.success) {
        Toast.clear();
        this.bankInfo = res.data;
      } else Toast.fail(res.message);
    },
    doSubmit() {
      if (!this.file) {
        Toast.fail("上传网银截图");
        return;
      }

      if (!this.amount) {
        Toast.fail("请输入充值金额");
        return;
      }

      this.uploadScreenshot();
    },
  },
};
</script>
<style lang="less" scoped>
.recharge-step {
  padding: 15px;
}

.saveButton {
  margin: 15px 15px 0 15px;
  padding-bottom: 15px;
  padding-top: 30px;
  text-align: center;
}

.van-button--normal {
  border-radius: 5px;
  padding: 0px 40px;
}

::v-deep .van-uploader__preview-image {
  width: 180px !important;
  height: 100px !important;
}

::v-deep .van-uploader__upload {
  width: 180px;
  height: 100px;
}
::v-deep .van-field__label {
  margin-right: 0px;
}
.van-cell {
  padding: 10px 0px;
}
</style>